$(".hamburger").on("click", function () {
  if (!$(this).hasClass("hamburger--active")) {
    $(this).addClass("hamburger--active");
    $(".header").addClass("sticky");
    $(".nav").addClass("active");
    $("body").css("overflow", "hidden");
    $(".mask").fadeIn(400);
  } else {
    $(this).removeClass("hamburger--active");
    $(".header").removeClass("sticky");
    $(".nav").removeClass("active");
    $("body").css("overflow", "initial");
    $(".mask").removeClass("active");
    $(".mask").fadeOut(400);
    if ($(window).outerWidth(true) < 575) {
      $(".nav__right-side").removeClass("active");
      $(".nav__content").removeClass("active");
      $(".nav__list--left li button").removeClass("active");
    }
  }
});

$(".nav__list--left li button").on("click", function () {
  const id = $(this).data("id");
  if (id) {
    $(".nav__right-side").addClass("active");
    $(".nav__list--left li button").removeClass("active");
    $(this).addClass("active");
    $(".nav__content").removeClass("active");
    $('.nav__content[data-id="' + id + '"]').addClass("active");
  }
});

$(".nav__back").on("click", function () {
  $(".nav__right-side").removeClass("active");
  $(".nav__content").removeClass("active");
  $(".nav__list--left li button").removeClass("active");
});
